import React, { useState } from "react";
import DealerList from "../dealer-list/dealerlist";
import { useSelector } from "react-redux";
import PaidApplicationBrowse from "./paidApplication";
import UnPaidApplicationBrowse from "./unpaidApplication";

const KenyaIndex = () => {
  const validation = localStorage.getItem("department");
  const userRightData = useSelector((state) => state.userRightData.value);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const handleIndex = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="application_wrapper">
      <div className="application_tabs">
        <div className="container-fluid">
          <ul className="nav nav-tabs nav-tabs-line">
            <h5>KENYA</h5>
            {userRightData[5]?.valid ? (
              <li className="nav-item">
                <a
                  className={
                    `nav-link ` + (selectedIndex === 0 ? "active" : "")
                  }
                  onClick={() => {
                    setSelectedIndex(0);
                  }}
                >
                  Paid Application
                </a>
              </li>
            ) : (
              ""
            )}
            {userRightData[6]?.valid ? (
              <li className="nav-item">
                <a
                  className={
                    `nav-link ` + (selectedIndex === 1 ? "active" : "")
                  }
                  onClick={() => {
                    handleIndex(1);
                  }}
                >
                  Unpaid Application
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>

      <div className="tab-content">
        {selectedIndex === 0 ? (
          userRightData[5]?.valid ? (
            <PaidApplicationBrowse />
          ) : (
            ""
          )
        ) : userRightData[6]?.valid ? (
          <UnPaidApplicationBrowse />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default KenyaIndex;
