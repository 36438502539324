import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { commonAPICall } from "../services/services";
import { showErrorToast, showSucessToast } from "./common";
import Select from "react-select";
import { ExcelExport } from "./excelExport";
import { useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px",
      fontWeight: "400",
      color: "#333",
      fontSize: "16px",
    },
  },
  headCells: {
    style: {
      backgroundColor: "#000", // override the cell padding for head cells
      color: "white",
      padding: "10",
      fontWeight: "600",
      fontFamily: "Sans-serif",
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      borderRight: "solid 1px #ddd", // override the cell padding for data cells
      borderCollapse: "collapse",
    },
  },
};

const BrijCustomDataTable = ({
  columns,
  url,
  apiBody,
  paramsBody,
  assingUrl,
  type,
  reloadGrid
}) => {
  const userRightData = useSelector((state) => state.userRightData.value);
  const [deoData, setdeoData] = useState([]);
  const [jsonUpdate, setjsonUpdate] = useState(reloadGrid);
  const [salesData, setsalesData] = useState([]);
  const [assingData, setassingData] = useState({
    tranID: [],
    sales_user_id: null,
    doe_user_id: null,
  });
  const [loading, setLoading] = useState(true);
  const [column, setColumn] = useState([]);
  const [browseData, setBrowseData] = useState({
    records: [],
    totalRecords: 0,
    recordFiltered: 0,
  });
  const [queryParam, setQueryParam] = useState(paramsBody);
  const toQueryString = (obj) => {
    return Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&");
  };

  const urgentApplicationRowStyle = [
    {
      when: (row) => row.application_type === "URGENT" || row.application_type === "Urgent",
      style: {
        backgroundColor: "#198753",
        color: "white",
      },
    }
  ];

  const handlePageChange = (page) => {
    setQueryParam({ ...queryParam, pageNo: page });
  };
  const handleColumnFilter = (ActionMeta, OnChangeValue) => {
    if (ActionMeta.length > 0) {
      const name =
        OnChangeValue.action == "select-option"
          ? OnChangeValue.option.name
          : OnChangeValue.removedValue.name;
      const filterValue = column.map((i, index) => {
        if (i.name === name) {
          return { ...i, omit: i.omit ? false : true };
        } else {
          return i;
        }
      });
      setColumn(filterValue);
    } else {
      const filterValue = column.map((i, index) => {
        return { ...i, omit: false };
      });
      setColumn(filterValue);
    }
  };
  // const handleSearch = (e) => {
  //   let currentQuery = { ...queryParam };
  //   currentQuery.search = e.target.value;
  //   setQueryParam({ ...queryParam, search: e.target.value });
  //   getBrowseData(currentQuery);
  // };
  const handleRowsChange = (row) => {
    setQueryParam({ ...queryParam, pageSize: row });
  };

  const getBrowseData = async (query) => {
    setLoading(true);
    try {
      const response = await commonAPICall(
        `${url}?${toQueryString(query)}`,
        "post",
        apiBody
      );
      setBrowseData({
        records: response.data,
        recordFiltered: response.recordsFiltered,
        totalRecords: response.totalRecord,
        excel: response.excel,
      });
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };
  const deoList = async () => {
    try {
      const response = await commonAPICall("/cm/CM_list_deo_users", "get", "");
      if (response.valid) {
        setdeoData(response.data);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };
  const salesList = async () => {
    try {
      const response = await commonAPICall(
        "/cm/CM_list_sales_users",
        "get",
        ""
      );
      if (response.valid) {
        setsalesData(response.data);
      }
    } catch (err) {
      showErrorToast(err);
    }
  };
  const assingInsert = async () => {
    try {
      if (
        assingData.tranID.length > 0 &&
        (assingData.doe_user_id || assingData.sales_user_id)
      ) {
        const response = await commonAPICall(assingUrl, "post", assingData);
        if (response.valid) {
          showSucessToast("Success");
          getBrowseData(queryParam);
        }
      } else {
        showErrorToast("Plese Select,Deo Manager Or Sales Manager");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };
  useEffect(() => {
    setQueryParam(paramsBody);
  }, [paramsBody, apiBody]);
  useEffect(() => {
    getBrowseData(queryParam);
  }, [apiBody, jsonUpdate, queryParam]);

  // useEffect(() => {
  //   console.log(userRightData)
  //   if (userRightData.length > 0) {
  //   console.log(columns.filter((value, ind) => {
  //     return userRightData.some((val) => {
  //       console.log(val.valid && val.name == value.name)
  //       if (val.valid && val.name == value.name) {
  //         return { ...value, label: value.name, value: value.name };
  //       }
  //     });
  //   }))

  //     setColumn(
  //       columns.filter((value, ind) => {
  //         return userRightData.some((val) => {
  //           if (val.valid && val.name == value.name) {
  //             return { ...value, label: value.name, value: value.name };
  //           }
  //         });
  //       })
  //     );
  //   }
  // }, [userRightData]);
  useEffect(() => {
    salesList();
    deoList();
  }, []);
  useEffect(() => {
    setColumn(
      columns.map((val) => {
        return { ...val, label: val.name, value: val.name };
      })
    );
  }, [columns]);
  useEffect(() => {
    setjsonUpdate(reloadGrid);
  }, [reloadGrid]);

  const handleRowClicked = (row) => {
    const updatedData = browseData.records.map((item) => {
      if (row.tran_id !== item.tran_id) {
        return item;
      }
      return {
        ...item,
        toggleSelected: !item.toggleSelected,
      };
    });
    setBrowseData({ ...browseData, records: updatedData });
  };
  const handleChange = (event) => {
    setassingData({
      ...assingData,
      [event.target.name]: Number(event.target.value)
        ? Number(event.target.value)
        : null,
    });
  };
  const handleChangeGrid = ({ selectedRows }) => {
    setassingData({ ...assingData, tranID: selectedRows });
  };
  const assingValidSelect = () => {
    if (type === "paid" && userRightData[8]?.valid) {
      return (
        <div className="col-md-2 mb-2">
          <Form.Select onChange={handleChange} name="doe_user_id" size="sm">
            <option value={null}>All DEO Executive</option>
            {deoData.map((val, ind) => (
              <option key={val.user_id} value={val.user_id}>
                {val.full_name}
              </option>
            ))}
          </Form.Select>
        </div>
      );
    } else if (type === "unpaid" && userRightData[9]?.valid) {
      return (
        <div className="col-md-2 mb-2">
          <Form.Select onChange={handleChange} name="sales_user_id" size="sm">
            <option value={null}>All Sales Executive</option>
            {salesData.map((val, ind) => (
              <option value={val.user_id}>{val.full_name}</option>
            ))}
          </Form.Select>
        </div>
      );
    } else {
      return "";
    }
  };
  const valid = () => {
    if (userRightData[8]?.valid && type === "paid") {
      return true;
    } else if (userRightData[9]?.valid && type === "unpaid") {
      return true;
    } else {
      return false;
    }
  };

  const selectStyles = {
    control: (provided) => ({ ...provided, fontSize: ".875em" }),
    menu: (provided) => ({ ...provided, fontSize: ".875em", backgroundColor: 'brown', display: 'block' }),
    option: (provided) => ({ ...provided, fontSize: ".875em", color: '#000', minHeight: '20px', padding: '0' }),
  };

  return (
    <div className="custom-datatable-component">
      <div className="container-fluid mb-1">
        <div className="row">
          <div className="col-md-3">
            <div className="dropdmenu">
              <Select
                options={column}
                placeholder={"Show Hide Column"}
                isMulti
                onChange={handleColumnFilter}
                closeMenuOnSelect={false}
                size="sm"
                className="show_hide_column"
                styles={selectStyles}
              />
            </div>
          </div>
          {assingValidSelect()}
          {valid() ? (
            <div className="col-md-1 mb-2">
              <Button variant="primary" size="sm" onClick={assingInsert}>
                Assign
              </Button>
            </div>
          ) : (
            ""
          )}
          {/* <div className="col-md-1 mb-2">
            <h5>Filter</h5>
          </div> */}
          {type === "paid" ? (
            <div className="col-md-2 mb-2">
              <Form.Select
                onChange={(event) =>
                  setQueryParam({
                    ...queryParam,
                    agent_id: Number(event.target.value),
                  })
                }
                name="doe_user_id"
                size="sm"
              >
                <option value={0}>All DEO Executive filter</option>
                {deoData.map((val, ind) => (
                  <option value={val.user_id}>{val.full_name}</option>
                ))}
              </Form.Select>
            </div>
          ) : (
            ""
          )}
          {type === "unpaid" ? (
            <div className="col-md-2 mb-2">
              <Form.Select
                onChange={(event) =>
                  setQueryParam({
                    ...queryParam,
                    agent_id: Number(event.target.value),
                  })
                }
                name="sales_user_id"
                size="sm"
              >
                <option value={0}>All Sales Executive filter</option>
                {salesData.map((val, ind) => (
                  <option value={val.user_id}>{val.full_name}</option>
                ))}
              </Form.Select>
            </div>
          ) : (
            ""
          )}
          <div className="col-md-2 mb-2">
            {userRightData[7]?.valid ? (
              <ExcelExport
                excelData={browseData.excel}
                fileName="Excel Export"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="grid_table_wrapper">
        {/* {console.log(column)} */}
        <DataTable

          columns={column}
          data={browseData.records}
          actions={""}
          pagination
          paginationServer
          onChangeRowsPerPage={handleRowsChange}
          onChangePage={handlePageChange}
          paginationTotalRows={browseData.totalRecords}
          progressPending={loading}
          dense
          fixedHeader
          highlightOnHover={false}
          onRowClicked={handleRowClicked}
          //select
          selectableRows={valid()}
          onSelectedRowsChange={handleChangeGrid}
          conditionalRowStyles={urgentApplicationRowStyle}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};
export default BrijCustomDataTable;
