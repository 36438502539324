import moment from "moment";
import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { Form, Button, Dropdown } from "react-bootstrap";
import BrijCustomDataTable from "../../components/brijDataTable";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaCog, FaEdit, FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import {
  BrijDateRange,
  showErrorToast,
  showSucessToast,
} from "../../components/common";
import Modal from "react-bootstrap/Modal";
import { mainUrl, previewLiveUrl } from "../../config";
import { commonAPICall } from "../../services/services";
import AngkorRemark from "./AngkorPopup";
const user_id = JSON.parse(localStorage.getItem("auth"))?.user_id;
const PaidApplicationBrowse = () => {
  const [selectStatus, setselectStatus] = useState({ tran_id: 0 });
  const [selectStatusApplication, setselectStatusApplication] = useState({
    tran_id: 0,
  });
  const [paymentData, setpaymentData] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [saleStatus, setSaleStatus] = useState([]);
  const [show, setShow] = useState(false);
  const [application, setapplication] = useState();
  const [showType, setshowType] = useState(false);
  const [bodyQuery, setbodyQuery] = useState({
    application_id: "",
    order_id: "",
    nationality: "",
    email: "",
    visitor_id: "",
    sales_manager_status: "",
    sales_status: "",
    deo_manager_status: "",
    deo_status: "",
    payment_amount: 0,
    filter_column: "",
    search: "",
    pageSize: 15,
    pageNo: 1,
    reload: 0,
  });
  const [apibody, setapibody] = useState({
    from_date: null,
    to_date: null,
    payment_from_date: null,
    payment_to_date: null,
    agent_id: null,
  });
  const [paidData, setPaidData] = useState({
    amount: null,
    id: null,
  });

  const fileHandle = async (e, tran_id) => {
    // let value = e.target.files[0];
    // let formData = new FormData();
    // formData.append("file_path", e.target.files[0]);
    if (e.target.files[0]) {
      //  await commonAPICall("/ank_file/ank_image","post",{
      //       file_path: e.target.files[0],
      //       tran_id: tran_id,
      //       user_id: user_id,
      //     }).then(result=>{
      //       console.log(result);
      //     })
      await axios({
        method: "post",
        url: "https://api.etouristservices.in/ank_file/ank_image",
        data: {
          file_path: e.target.files[0],
          tran_id: tran_id,
          user_id: user_id,
        },
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((result) => {
          // if(result.data.valid){
          setbodyQuery({ ...bodyQuery, reload: bodyQuery.reload + 1 });
          // }
        })
        .catch((err) => {
          console.err(err);
        });
    }
  };
  const handleSubmit = async () => {
    try {
      const result = await commonAPICall("/ank/extra_payment_update", "post", {
        temporary_id: 0,
        amount: paidData.amount,
      });
      setShow(false);
    } catch (err) {
      showErrorToast(err);
    }
  };
  const handleShow = (id, amount) => {
    setPaidData({ ...paidData, id: id, amount: amount });
    setShow(true);
  };
  const openRemarks = (e, tran_id) => {
    setapplication(tran_id);
    setshowType(!showType);
  };
  const handleButtonClick = (e, temporary_id, tran_id) => {
    const url = `${previewLiveUrl}/preview/angkor/${temporary_id}/${tran_id}`;
    const windowOptions = "width=600,height=400,scrollbars=yes";
    window.open(url, "_blank", windowOptions);
  };

  const paymentApiCall = async () => {
    try {
      const result = await commonAPICall("/ank/list_payment_amount", "post", {
        from_date: apibody.from_date,
        to_date: apibody.to_date,
      });
      setpaymentData(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateApplicationStatus = async () => {
    try {
      const result = await commonAPICall(
        "/ank/AG_browse_application_status",
        "get",
        // { user_id: "" }
        ""
      );

      setApplicationStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateApplicationSaleStatus = async () => {
    try {
      const result = await commonAPICall(
        "/ank/AG_browse_application_sales_status",
        "get",
        // { user_id: "" }
        ""
      );
      setSaleStatus(result.data);
      // setApplicationSaleStatus(result.data);
    } catch (err) {
      showErrorToast(err);
    }
  };

  const updateSalesStatus = async (id, element) => {
    try {
      const body = {
        tran_id: selectStatus.tran_id,
        sales_status: element.target.value,
      };
      setselectStatus({ ...selectStatus, sales_status: element.target.value });
      const result = await commonAPICall(
        "/ank/update_sales_status",
        "post",
        body
      );
      if (result.status === 200) {
        showSucessToast("Sales Status Update");
      }
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    updateApplicationStatus();
    updateApplicationSaleStatus();
  }, []);
  // useEffect(() => {
  //   paymentApiCall();
  // }, [apibody.from_date, apibody.to_date]);

  const handleChange = (e) => {
    setbodyQuery({ ...bodyQuery, [e.target.name]: e.target.value });
  };

  const dateFilter = (date, type) => {
    const startDate = moment(date.startDate).format("MM/DD/YYYY");
    const endDate = moment(date.endDate).format("MM/DD/YYYY");
    if (type[2] === "application") {
      setapibody({ ...apibody, from_date: startDate, to_date: endDate });
    }
    if (type[2] === "payment") {
      setapibody({
        ...apibody,
        payment_from_date: startDate,
        payment_to_date: endDate,
      });
    }
  };
  const handleShowType = (type) => {
    setshowType(type);
  };

  const handleSend = (email, ticket_path) => {
    axios
      .post("https://api.etouristservices.in/ank_file/ank_sendMail", {
        email: email,
        ticket_path: ticket_path,
      })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = useMemo(
    () => [
      {
        id: "apply_date",
        name: "Apply Date",
        selector: (row) => moment(row.datetime).format("DD/MM/YYYY"),
        sortable: true,
        omit: false,
        minWidth: "110px",
      },
      {
        id: "payment_date",
        name: "Date of Payment",
        selector: (row) => {
          return (
            <div className="table__dttime">
              {moment(
                row.payment_date ? row.payment_date.replace("Z", "") : null
              ).format("DD/MM/YYYY")}
              <span>
                {" "}
                {moment(
                  row.payment_date ? row.payment_date.replace("Z", "") : null
                ).format("hh:mm:ss A")}
              </span>
            </div>
          );
        },
        sortable: true,
        omit: false,
        minWidth: "150px",
      },
      {
        id: "datetime",
        name: "Visit Date",
        selector: (row) => {
          return (
            <div className="table__dttime">
              {moment(
                row.datetime ? row.datetime.replace("Z", "") : null
              ).format("DD/MM/YYYY")}
              <span>
                {" "}
                {moment(
                  row.datetime ? row.datetime.replace("Z", "") : null
                ).format("hh:mm:ss A")}
              </span>
            </div>
          );
        },
        sortable: true,
        omit: false,
        minWidth: "120px",
      },
      {
        id: "Application Id",
        name: "Application Id",
        selector: (row) => row.temporary_id,
        sortable: true,
        omit: false,
        minWidth: "150px",
      },
      {
        id: "Applicant Name",
        name: "Applicant Name",
        selector: (row) => row.Name,
        cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.Name}</div>,
        sortable: true,
        omit: false,
        minWidth: "200px",
      },
      {
        id: "Applicant Email   ",
        name: "Applicant email",
        selector: (row) => row.email,
        sortable: true,
        omit: false,
        minWidth: "180px",
        cell: (row) => <div style={{ whiteSpace: "normal" }}>{row.email}</div>,
      },
      {
        id: "temple_name",
        name: "Temple Name",
        selector: (row) => (
          <div style={{ whiteSpace: "normal" }}>{row.ticket}</div>
        ),
        sortable: true,
        omit: false,
        minWidth: "200px",
      },
      {
        id: "visit_days",
        name: "Visit Days",
        selector: (row) => row.visit_days,
        sortable: true,
        omit: false,
        minWidth: "100px",
      },
      {
        id: "amount",
        name: "Amount",
        selector: (row) => <span>{row.amount}USD</span>,
        sortable: true,
        omit: false,
        minWidth: "100px",
      },
      {
        id: "tickets_status",
        name: "Tickets Status",
        selector: (row) => row.tickets_status,
        sortable: true,
        omit: false,
        minWidth: "130px",
      },
      {
        id: "email_status",
        name: "Email Status",
        selector: (row) => row.email_status,
        sortable: true,
        omit: false,
        minWidth: "110px",
      },
      {
        id: " upload Ticket ",
        name: "Upload Ticket",
        omit: false,
        selector: (row) => {
          return (
            <div className=" customBtn_r">
              <input type="file" onChange={(e) => fileHandle(e, row.tran_id)} />
            </div>
          );
        },
        sortable: true,
        minWidth: "220px",
      },
      {
        id: "tickets",
        name: "Tickets",
        selector: (row) =>
          row.ticket_path ? "ticket Uploaded" : "Not available",
        sortable: true,
        omit: false,
        minWidth: "120px",
      },
      {
        id: " send Ticket ",
        name: "Send Ticket",
        omit: false,
        selector: (row) => {
          return (
            <div className="customBtn_p">
              <Button
                variant="warning"
                onClick={() =>
                  window.open(
                    `https://api.etouristservices.in/uploads/${row.ticket_path}`,
                    "",
                    "width=600,height=300"
                  )
                }
                className="p-1 m-1 bg-warning  text-dark"
              >
                Preview
              </Button>

              <Button
                variant="info"
                onClick={() => {
                  const check = confirm("Would you like to send the email?");
                  if (check) {
                    handleSend(row.email, row.ticket_path);
                    showSucessToast("Mail Sent Successfully");
                  } else {
                    showErrorToast("Unsuccessful");
                    return;
                  }
                }}
                className="p-1 m-1 bg-info text-dark"
              >
                Send
              </Button>
            </div>
          );
        },
        sortable: true,
        minWidth: "120px",
      },
      {
        id: "action",
        name: "Action",
        omit: false,
        minWidth: "70px",
        selector: (row) => {
          return (
            <>
              <Dropdown className="action_dropdown">
                <Dropdown.Toggle variant="success" size="sm">
                  <FaCog />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={(e) => openRemarks(e, row.tran_id)}>
                    Remarks
                  </Dropdown.Item>

                  <Dropdown.Item
                    onClick={(e) =>
                      handleButtonClick(e, row.temporary_id, row.tran_id)
                    }
                  >
                    View
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => {}}>Delete</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          );
        },
        sortable: true,
      },

      // {
      //   id: " Nationality  ",
      //   name: " Nationality  ",
      //   selector: (row) => row.nationality,
      //   sortable: true,
      //   omit: false,
      // },

      // {
      //   id: "mobile",
      //   name: "Mobile",
      //   selector: (row) => "+" + row.country_code + "-" + row.mobile,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "160px",
      // },

      // {
      //   id: "datetime",
      //   name: "Payment Record",
      //   selector: (row) => {
      //     return moment(row.datetime).format("DD/MM/YYYY");
      //   },
      //   omit: false,
      //   sortable: true,
      //   minWidth: "200px",
      // },
      // {
      //   id: "date",
      //   name: "Travel Date",
      //   selector: (row) => {
      //     return moment(row.date).format("DD/MM/YYYY");
      //   },
      //   omit: false,
      //   sortable: true,
      //   minWidth: "180px",
      // },
      // {
      //   id: "pay_amount",
      //   name: "Pay Amount",
      //   selector: (row) => row.pay_amount + row.currency,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "160px",
      // },
      // {
      //   id: "payment",
      //   name: "Payment",
      //   selector: (row) => row.payment,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "160px",
      // },
      // {
      //   id: "Visit Temple",
      //   name: "Visit Temple",
      //   selector: (row) => row.ticket,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "280px",
      // },
      // {
      //   id: "visit_date",
      //   name: "Visit Date",
      //   selector: (row) => {
      //     return moment(row.visit_date).format("DD/MM/YYYY");
      //   },
      //   sortable: true,
      //   omit: false,
      //   minWidth: "160px",
      // },
      // {
      //   id: "visit_time",
      //   name: "Visit Time",
      //   selector: (row) => row.visit_time,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "165px",
      // },

      // {
      //   id: "deo_manager_status",
      //   name: "Deo Manager Status",
      //   selector: (row) => row.deo_manager_status,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "250px",
      // },
      // {
      //   id: "sales_status",
      //   name: "Sales Status",
      //   minWidth: "230px",
      //   omit: false,
      //   selector: (row) => {
      //     return (
      //       <div className="w-100">
      //         {selectStatus?.tran_id !== row.tran_id ? (
      //           <p
      //             style={{ cursor: "pointer" }}
      //             className="grid_select_value"
      //             onClick={() => setselectStatus(row)}
      //           >
      //             {row.sales_status}
      //           </p>
      //         ) : (
      //           <select
      //             name="sale Status"
      //             className="grid_select_input"
      //             value={selectStatus?.sales_status}
      //             onChange={updateSalesStatus}
      //           >
      //             {saleStatus.map((value) => (
      //               <option value={value.status_name}>
      //                 {value.status_name}
      //               </option>
      //             ))}
      //           </select>
      //         )}
      //       </div>
      //     );
      //   },
      //   sortable: true,
      // },
      // {
      //   id: "deo_manager_status",
      //   name: "Deo Manager Status",
      //   selector: (row) => row.deo_manager_status,
      //   sortable: true,
      //   omit: false,
      // },

      // {
      //   id: "deo_user_name",
      //   name: "Deo User Name",
      //   selector: (row) => row.doe_user_name,
      //   sortable: true,
      //   omit: false,
      //   minWidth: "200px",
      // },

      // {
      //   id: "move_to_paid",
      //   name: "Move to Unpaid",
      //   omit: false,
      //   selector: (row) => {
      //     return (
      //       <div className="customBtn_p">
      //         <Button
      //           onClick={() => handleShow(row.temporary_id, row.pay_amount)}
      //           className="p-2"
      //         >
      //           Move to unpaid
      //         </Button>
      //       </div>
      //     );
      //   },
      //   sortable: true,
      //   minWidth: "180px",
      // },
    ],
    [applicationStatus, saleStatus, selectStatus, selectStatusApplication]
  );
  return (
    <div className="browse-list">
      <div className="application_form_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.application_id}
                onChange={handleChange}
                type="text"
                name="application_id"
                placeholder="Application Id"
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.search}
                onChange={handleChange}
                type="text"
                name="search"
                placeholder="Search"
              />
            </div>
            <div className="col-md-4  mb-2">
              <Form.Control
                size="sm"
                value={bodyQuery.email}
                onChange={handleChange}
                type="text"
                name="email"
                placeholder="Email"
              />
            </div>
            <div className="col-md-2  mb-1">
              <Form.Control
                size="sm"
                value={bodyQuery.nationality}
                onChange={handleChange}
                type="text"
                name="nationality"
                placeholder="Nationality"
              />
            </div>
            <div className="col-md-2  mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Application"
                pickClass={"right"}
              />
            </div>
            <div className="col-md-2  mb-1">
              <BrijDateRange
                onChange={dateFilter}
                dateLevel="Date Of Payment"
                pickClass={"left"}
              />
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select size="sm" onChange={handleChange} name="ticket">
                <option value={""}>Select Temple</option>
                <option value={"KOHKER TEMPLE"}>KOHKER TEMPLE</option>
                <option value={"ANGKOR WORLD HERITAGE"}>
                  ANGKOR WORLD HERITAGE
                </option>
              </Form.Select>
            </div>
            {/* <div className="col-md-2  mb-1">
              <Form.Select 
              size="sm"
                onChange={handleChange}
                name="sales_manager_status"
               
              >
                <option value={""}>Sales Manager Status</option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div> */}
            <div className="col-md-2 mb-1">
              <Form.Select
                size="sm"
                name="sales_status"
                onChange={handleChange}
              >
                <option value={""}>Sales Status </option>
                <option>Fresh Application</option>
                <option>Will Pay Soon</option>
                <option>No Call Response</option>
                <option>Voice Message</option>
                <option>Paid</option>
                <option>Close</option>
                <option>Others</option>
              </Form.Select>
            </div>
            {/* <div className="col-md-2 mb-1">
              <Form.Select 
              size="sm"
                name="deo_manager_status"
                onChange={handleChange}
               
              >
                <option value={""}>Deo Manager Status </option>
                <option>Not Assigned</option>
                <option>Assigned</option>
              </Form.Select>
            </div> */}
            <div className="col-md-2 mb-1">
              <Form.Select size="sm" name="deo_status" onChange={handleChange}>
                <option value={""}>Application Status </option>
                <option>Fresh Application</option>
                <option>Complete Application</option>
                <option>Pending</option>
                <option>Wants to Cancle</option>
                <option>Refund</option>
                <option>Hold</option>
                <option>Late Arrival</option>
              </Form.Select>
            </div>
            <div className="col-md-2 mb-1">
              <Form.Select
                size="sm"
                name="payment_amount"
                onChange={handleChange}
              >
                <option value={""}>Payment Amount </option>
                {paymentData?.map((list) => (
                  <option>{list.pay_amount}</option>
                ))}
              </Form.Select>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <BrijCustomDataTable
          columns={columns}
          url="/ank/main_browse_paid"
          apiBody={apibody}
          paramsBody={bodyQuery}
          assingUrl="/ank/AG_Application_assign_insert"
          type={"paid"}
        />
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="fs-4">
                Application Id : <span>{paidData.id}</span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex gap-3">
              <h4>Amount: </h4>
              <span>
                <input
                  type="number"
                  value={paidData.amount}
                  onChange={(e) =>
                    setPaidData({ ...paidData, amount: e.target.value })
                  }
                />
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="success" onClick={handleSubmit}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <AngkorRemark
        showtype={showType}
        handleshowType={handleShowType}
        data={application}
      />
    </div>
  );
};

export default PaidApplicationBrowse;
